<template>
    <div class="row">
        <div class="col-12">

            <div class="form-group">
                <label for="name">Search</label>
                <input type="text" v-model="search" class="form-control form-control-dark" />
            </div>

            <table class="table table-striped table-hover tx-white">
                <thead class="thead-colored thead-primary">
                    <tr>
                        <th>Name</th>
                        <th>Postcode</th>
                        <th>
                            <button class="btn btn-sm btn-success float-right" @click="showAddModal">Add</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="company of filteredDetails">
                        <td>{{ company.name }}</td>
                        <td>{{ company.postcode }}</td>
                        <td>
                            <button class="btn btn-sm btn-primary" @click="showEditModal(company)">Edit</button>
                            <button class="btn btn-sm btn-danger" @click="deleteDetails(company)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div id="addCompanyModal" class="modal fade" style="display: none; padding-right: 17px;">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-dark tx-white tx-size-sm">
                        <div class="modal-header bg-dark pd-x-20">
                            <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Common Transfer</h6>
                            <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body bg-dark tx-white pd-20">
    
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" v-model="company.name" class="form-control form-control-dark" />
                            </div>

                            <div class="form-group">
                                <label for="address_one">Address One</label>
                                <input type="text" v-model="company.address_one" class="form-control form-control-dark" />
                            </div>

                            <div class="form-group">
                                <label for="address_two">Address Two</label>
                                <input type="text" v-model="company.address_two" class="form-control form-control-dark" />
                            </div>

                            <div class="form-group">
                                <label for="address_three">Address Three</label>
                                <input type="text" v-model="company.address_three" class="form-control form-control-dark" />
                            </div>

                            <div class="form-group">
                                <label for="address_four">Address Four</label>
                                <input type="text" v-model="company.address_four" class="form-control form-control-dark" />
                            </div>

                            <div class="form-group">
                                <label for="postcode">Postcode</label>
                                <input type="text" v-model="company.postcode" class="form-control form-control-dark" />
                            </div>

                            <button v-if="!company.id" class="btn btn-primary float-right" @click="postDetails">Create</button>
                            <button v-if="company.id" class="btn btn-primary float-right" @click="patchDetails">Update</button>
                            
                        </div><!-- modal-body -->
                        <div class="modal-footer bg-dark tx-white">
                            <button type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div><!-- modal-dialog -->
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: 'CommonDetails',
    data(){
        return {
            details: [],
            search: "",
            company: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                postcode: ""
            }
        }
    },
    mounted(){
        this.getDetails();
    },
    methods: {
        getDetails(){
            axios.get(`https://api.varsanpr.com/api/documents/common`, {
                headers: authHeader()
            })
            .then(response => {
                this.details = response.data.details;
            })
            .catch(error => {
                this.$error("Failed fetching details.", error);
                console.error(error);
            });
        },
        deleteDetails(details){
            axios.delete(`https://api.varsanpr.com/api/documents/common`, {
                data: {
                    id: details.id
                },
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully deleted details.");
                this.getDetails();
            })
            .catch(error => {
                this.$error("Failed deleting details.", error);
                console.error(error);
            });
        },
        postDetails(){
            axios.post(`https://api.varsanpr.com/api/documents/common`, this.company, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully added details.");
                this.getDetails();
                this.company = {
                    name: "",
                    address_one: "",
                    address_two: "",
                    address_three: "",
                    address_four: "",
                    postcode: ""
                }
                $('#addCompanyModal').modal('toggle');
            })
            .catch(error => {
                this.$error("Failed adding details.", error);
                console.error(error);
            });
        },
        patchDetails(){
            axios.patch(`https://api.varsanpr.com/api/documents/common`, this.company, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully updated details.");
                this.getDetails();
                this.company = {
                    name: "",
                    address_one: "",
                    address_two: "",
                    address_three: "",
                    address_four: "",
                    postcode: ""
                }
                $('#addCompanyModal').modal('toggle');
            })
            .catch(error => {
                this.$error("Failed updating details.", error);
                console.error(error);
            });
        },  
        showAddModal(){
            $('#addCompanyModal').modal('toggle');
        },
        showEditModal(details){
            this.company.name = details.name;
            this.company.address_one = details.address_one;
            this.company.address_two = details.address_two;
            this.company.address_three = details.address_three;
            this.company.address_four = details.address_four;
            this.company.postcode = details.postcode;
            this.company.id = details.id;
            $('#addCompanyModal').modal('toggle');
        }
    },
    computed: {
        filteredDetails: function(){
            if(this.search == null || this.search == ""){
                return this.details;
            }
            return this.details.filter((d) => {
                return d.name.includes(this.search) || d.postcode.includes(this.search);
            })
        }
    }
}
</script>